.lit-shopify-container {
    margin: 0;
    padding: 0;
    background-color: #efecec;
    height: 100vh;
    width: 100vw;
}

.lit-loading-container {
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.lit-fail-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.lit-success-container {
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.lit-success-container, .lit-fail-container {
    animation: fade-in 1s;
}

.lit-info {
    animation: fade-in 1s;
    position: absolute;
    bottom: 0;
    right: 0;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 500px) {
    .lit-success-container {
        width: 100%;
    }

    .lit-success-container .MuiPaper-root {
        overflow-y: scroll;
    }

    .lit-fail-container {
        left: 0;
        transform: translate(0, -50%);
    }

    .lit-info {
        display: none;
    }
}