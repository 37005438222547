.fail-card-header {
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    justify-content: center;
}


.fail-card-header-icon {
    margin-right: 0.25em;
}

.fail-card-contents p:first-child {
    margin-top: 0;
}
