.Meeting {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Meetings {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.MeetingColumn {
  margin: 8px;
}
