.braveNotification {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  overflow-wrap: break-word;
  font-size: 0.8rem;
  max-width: 70%;
}

.share-modal-container {
  width: 500px;
  height: 700px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #333;
}