.links-card {
  border: 0px solid transparent;
  //width: 81rem;
  margin: 0 auto;
  box-shadow: none;
}

.links-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}

.links-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.access-control-list-item {
  font-size: 0.8rem;
  border: 1px solid #5E36B7;
  border-radius: 4px;
  padding: 0.25rem;
  margin-bottom: 0.3rem;
}
