.App {
  text-align: center;
}

.split {
  display: flex;
  justify-content: space-around;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top-margin-buffer {
  margin-top: 1rem;
}

.left-margin-buffer {
  margin-left: 1rem;
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
}

.picker-dialog-bg {
  z-index: 100 !important;
}

.picker-dialog {
  z-index: 101 !important;
}

// shared grid scss
.service-grid-container {
  display: grid;
  grid-template-rows: 4rem 10rem 20rem 15rem;
  grid-template-columns: 12% 8% 60% 8% 12%;
  width: 100%;
}

.service-grid-header {
  grid-row: 1 / 4;
  grid-column: 3 / 4;
}

.service-grid-links {
  grid-row: 3;
  grid-column: 2 / 5;
}

.service-grid-login {
  //grid-row: 2;
  //grid-column: 3 / 4;
  min-width: 50rem;
  width: 80%;
  border: none;
  box-shadow: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #5e36b7;
}

.service-loader {
  width: 40rem;
  height: 6rem;
  position: absolute;
  top: 35vh;
  left: calc(50vw - 20rem);
}

.login-container-top {
  display: flex;
  justify-content: space-between;
}

.login-service {
  display: flex;
  align-items: center;
}

.service-image {
  height: 3rem;
  width: 3rem;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
}

.service-title {
  width: 15rem;
  text-align: left;
  margin: 0 0 0 1rem;
  padding: 0;
}

.service-category {
  width: 6rem;
  background-color: #e2e2e2;
  margin: 0.5rem 0 0 1rem;
  padding: 0.25rem;
  font-size: 0.8rem;
}

.service-launch-button {
  width: 10.3rem;
  height: 2.3rem;
  cursor: pointer;
}

.service-launch-button-google {
  height: 2.5rem;
  cursor: pointer;
}

.service-launch-button-google {
  height: 2.5rem;
  cursor: pointer;
}

.service-description {
  text-align: left;
  margin: 8rem auto 0;
  width: 70%;
}

.unselected-services-container {
  //grid-column: 3 / 4;
  //grid-row-start: 4;
  min-width: 50rem;
  width: 80%;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.unselected-services {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.unselected-services-title {
  width: 100%;
  text-align: left;
  margin: 0 1rem;
}

.unselected-service-card {
  margin: 1rem;
}

.unselected-service-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

//access service card scss

.access-service-card-container {
  position: absolute;
  width: 70rem;
  height: 34rem;
  top: calc(45% - 17rem);
  left: calc(50% - 35rem);
  filter: drop-shadow(0px 4px 32px rgba(0, 0, 0, .25));
}

.access-service-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(19, 5, 50, .15);
  top: 0;
  left: 0;
  z-index: 0;
}

.access-service-card {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.access-service-card-header {
  border-top: 0.5rem solid #5e36b7;
  height: 7rem;
  background-color: #f0eef5;
  display: flex;
  justify-content: space-between;
}

.access-service-card-header-left {
  width: 60%;
  display: flex;
  justify-content: flex-start;

  h1 {
    font-size: 2rem;
    font-weight: 500;
    margin: 1rem 0 0 1rem;
    color: #2C0C72;
  }
}

.access-service-card-logo {
  margin-left: 1rem;
  width: 5rem;
  height: 6rem;
  background-size: contain;
  background-repeat: no-repeat;
}

.access-service-card-title {
  max-width: 16rem;
  text-align: left;
  color: #2C0C72;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
    margin-top: 2rem;
    padding-bottom: 0;
  }

  p {
    font-size: 0.8rem;
    margin-top: 0;
  }
}

.access-service-card-header-right a {
  text-decoration: none;
}

.access-service-card-header-right p {
  margin-top: 0;
  color: #2C0C72;
  display: flex;
  align-items: center;

  .lit-gateway-title {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}

.access-service-card-content {
  height: 16.5rem;
}

.access-service-card-google-content {
  text-align: left;
  width: 80%;
  margin: 0 auto;
}

.access-service-card-actions {
  height: 6rem;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}

.access-service-card-launch-button {
  height: 100%;
  min-width: 25rem;
  background-color: #5e36b7;
  display: flex;
  font-size: 1.5rem;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
  transition-duration: 0.25s;
  cursor: pointer;
}

.access-service-card-launch-button:hover {
  opacity: 0.9;
}

.disabled-access-service-card-launch-button {
  height: 100%;
  min-width: 25rem;
  background-color: #5e36b7;
  display: flex;
  font-size: 1.5rem;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
  transition-duration: 0.25s;
  filter: grayscale(1);
}
