.lit-loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lit-loader div {
    position: absolute;
    border: 4px solid #333;
    opacity: 1;
    border-radius: 50%;
    animation: lit-loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lit-loader div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lit-loader {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.lit-loader-text {
    animation: fade-in 1s;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}