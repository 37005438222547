.product-card-container {
    margin: 0.5em;
    padding: 1em;

}

.product-card-top-row {
    display: flex;
    margin-bottom: 1em;
}

.product-card-top-row-left {

}

.product-image {
    width: 10em;
    height: 10em;
    border: 1px solid #bbb;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25em;
}

.product-card-top-row-right {
    padding: 1em;
}

.product-card-title {
    margin: 0;
    padding: 0;
    font-weight: 500;
}

@media only screen and (max-width: 500px) {
    .product-card-container {
        width: 100%;
    }

    .product-card-top-row {
        flex-direction: column;
        align-items: center;
    }
}