.provision-access-current-controls {
  display: flex;
  flex-direction: column;
}

.provision-access-control-item {
  border: 1px solid #5E36B7;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 0.3rem;
}
