.shopify-service-card-container {
  position: absolute;
  width: 80%;
  max-width: 900px;
  min-height: 28rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shopify-service-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #efecec;
  top: 0;
  left: 0;
}

.shopify-service-card {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 0 5rem;
}

.shopify-service-card-header {
  height: 5rem;
  display: flex;
  justify-content: space-between;
}

.shopify-service-card-header-left {
  width: 60%;
  display: flex;
  justify-content: flex-start;

  h1 {
    font-size: 2rem;
    font-weight: 500;
    margin: 1rem 0 0 1rem;
    color: rgb(38, 38, 38);
  }
}

.shopify-service-card-logo {
  margin-left: 1rem;
  width: 5rem;
  height: 6rem;
  background-size: contain;
  background-repeat: no-repeat;
}

.shopify-service-card-title {
  max-width: 16rem;
  text-align: left;
  color: rgb(38, 38, 38);

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
    margin-top: 2rem;
    padding-bottom: 0;
  }

  p {
    font-size: 0.8rem;
    margin-top: 0;
  }
}

.shopify-service-card-header-right {
  margin-right: 1rem;
}

.shopify-service-card-header-right a {
  text-decoration: none;
}

.shopify-service-card-header-right p {
  margin-top: 1.5rem;
  color: rgb(38, 38, 38);
  display: flex;
  align-items: center;
  font-size: 0.8rem;

  .lit-gateway-title {
    margin-left: 0.3rem;
    margin-right: 0.3rem;

  }
}

.shopify-service-card-content {
  height: 16.5rem;
}

.shopify-service-card-actions {
  height: 6rem;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}

.shopify-service-card-launch-button {
  height: 100%;
  min-width: 25rem;
  background-color: rgb(36, 78, 112);
  display: flex;
  font-size: 1.5rem;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
  transition-duration: 0.25s;
  cursor: pointer;
}

.shopify-service-card-launch-button:hover {
  opacity: 0.9;
}

.disabled-shopify-service-card-launch-button {
  height: 100%;
  min-width: 25rem;
  background-color: rgb(36, 78, 112);
  display: flex;
  font-size: 1.5rem;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
  transition-duration: 0.25s;
  filter: grayscale(1);
}

.shopify-service-card-loader {
  width: 100%;
}

@media only screen and (max-width: 700px) {
  .shopify-service-card-container {
    position: static;
    width: 100vw;
    height: 100vh;
    transform: none;
  }

  .shopify-service-card-header {
    //display: none;
    flex-direction: column;
    margin-top: 1rem;
    height: auto;

    .shopify-service-card-header-left, .shopify-service-card-header-right {
      padding: 0;
      width: auto;
      margin: 0 auto;

      h1 {
        font-size: 1rem;
        padding: 0;
        margin: 0;
      }

      p {
        padding: 0;
        margin: 0.5rem 0 0;
      }
    }
  }

  .shopify-service-card {
    padding: 0;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    height: 100vh;
    overflow-y: scroll;
  }

  .shopify-service-card-content {
    position: fixed;
    bottom: 7%;
    //margin-top: 5rem;
    width: 100%;
    height: auto;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadein;
  animation-duration: 0.3s;
}

