.request-link-card {
  width: 50vw;
  //height: 50vh;
  position: absolute;
  top: 25vh;
  left: 25vw;
}

.request-link-actions {
  display: flex;
  justify-content: space-between;
}
