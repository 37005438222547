.header-card {
  background-color: #e7e3ee !important;
  border-radius: 0 0 2rem 2rem !important;
  box-shadow: none !important;
  //width: 57rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 2rem 1rem;
  border-top: #5E36B7 0.75rem solid;
}

.header-left, .header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-service-image {
  background-size: contain;
  background-repeat: no-repeat;
  height: 3rem;
  width: 3rem;
}

.service-text {
  font-size: 12px;
}

.header-user-menu {
  background-color: #fff !important;
  color: #000 !important;

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }

  .user-email {
    font-size: 11px;
    color: #444 !important;
  }
}

.right-buffer {
  margin-right: 1rem;
}
